import { useQuery } from '@tanstack/react-query';
import { BasicModal, ModalHandle } from 'common/components';
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import locationLogsService from 'services/location-logs.service';
import { formatDate } from 'utils/date.utils';
import { LocationLogsMap, LocationLogsMapHandle } from './_LocationLogMap';

export interface LocationLogsHandle {
  show: () => void;
}

const _LocationLogs: ForwardRefRenderFunction<LocationLogsHandle> = (
  _,
  ref
) => {
  // Refs
  const summaryLogsRef = useRef<ModalHandle>(null);
  const locationLogMapRef = useRef<LocationLogsMapHandle>(null);

  // Services
  const { data: summaryLogs, remove } = useQuery({
    queryKey: ['Logs'],
    queryFn: () => locationLogsService.getAllSummary(),
  });

  useImperativeHandle(ref, () => ({
    show: () => {
      summaryLogsRef.current?.show();
    },
  }));

  return (
    <>
      <BasicModal onClose={remove} ref={summaryLogsRef} title="Location logs">
        <div className="min-w-[30vw] overflow-y-auto max-h-[60vh] px-5">
          <div>Log dates:</div>
          <ul>
            {summaryLogs?.map(({ id, createdAt }) => (
              <li
                key={id}
                onClick={() => locationLogMapRef.current?.show(id)}
                className="py-2"
              >
                <span className="cursor-pointer underline  ">
                  {formatDate(createdAt)}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </BasicModal>
      <LocationLogsMap ref={locationLogMapRef} />
    </>
  );
};

export const LocationLogs = forwardRef(_LocationLogs);
