/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  useRef,
  ReactElement,
  forwardRef,
  ReactNode,
} from 'react';
import { BasicModal, ModalHandle } from './Modal';

import { Button } from 'common/components/Button';
import { AxiosError } from 'axios';
import { Alert } from '@mui/material';
// Types
export interface FormModalHandle {
  show: () => void;
  hide: () => void;
}

export interface FormModalProps {
  onSave?: () => void;
  onReset?: () => void;
  onClose?: () => void;
  onSend?: () => void;
  children: ReactNode | ReactElement;
  isDirty?: boolean;
  isLoading?: boolean;
  title?: string;
  contentClassName?: string;
  error?: AxiosError<unknown, any> | undefined;
}

export interface Actions {
  title: string;
  disabled: boolean;
  onClick: () => void;
}

const _FormModal: ForwardRefRenderFunction<FormModalHandle, FormModalProps> = (
  {
    title = '',
    onSave,
    onReset,
    onClose = () => null,
    onSend,
    children,
    isDirty = false,
    isLoading,
    contentClassName = '',
    error = undefined,
  }: FormModalProps,
  ref
) => {
  const modalRef = useRef<ModalHandle>(null);

  const show = () => {
    modalRef.current?.show();
  };
  const actions: Actions[] = [];

  if (onSave) {
    actions.push({
      title: 'Save',
      disabled: isDirty,
      onClick: () => onSave(),
    });
  }
  if (onSend) {
    actions.push({
      title: 'Send',
      disabled: isDirty,
      onClick: () => onSend(),
    });
  }

  if (onReset) {
    actions.push({
      title: 'Reset',
      disabled: isDirty,
      onClick: () => onReset(),
    });
  }

  const hide = () => {
    modalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  return (
    <BasicModal
      title={title}
      ref={modalRef}
      className="w-full"
      isLoading={isLoading}
      onClose={onClose}
      contentClassName="max-h-[75vh]"
    >
      <div className="min-w-full">
        <div
          className={`mx-auto  overflow-y-auto max-h-[60vh] px-7 pt-4 md:px-9 ${contentClassName}`}
        >
          {error && <Alert severity="error">{error.message}</Alert>}
          {children}
        </div>
        {actions.length > 0 && (
          <div className="sticky bottom-0 pt-2 flex flex-row-reverse w-full bg-white  z-[13000]">
            <div className="flex flex-row-reverse w-full gap-2 pb-2 pt-[2px] pr-9">
              {actions.map(({ disabled, onClick, title }, index) => (
                <Button
                  bgColor="GREEN_PRIMARY"
                  key={`${title}_${index}`}
                  disabled={disabled}
                  variant="contained"
                  onClick={() => onClick?.()}
                >
                  <span className="capitalize">{title}</span>
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </BasicModal>
  );
};

export const FormModal = forwardRef(_FormModal);
