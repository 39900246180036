import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Type
import { CreatePoi, POI, UpdatePoi } from 'types/poi.type';

// Components
import { BaseInput, FormModal } from 'common/components';
import { MultipleSelect } from 'common/components/Select';
import { FormModalHandle } from 'common/components/Modal/FormModal';

// Utils
import { formatToInputDate } from 'utils/date.utils';

// Constants
import { emptyPoi } from 'constants/poi';

// Services
import poiService from 'services/poi.service';
import PoiMap from 'pages/Poi/PoiMap';

export interface PoiFormHandle {
  // eslint-disable-next-line no-unused-vars
  show: (poi?: POI, disable?: boolean) => void;
  hide: () => void;
}

interface PoiFormProps {
  title?: string;
}

const actuallyYear = new Date().getFullYear().valueOf();

const schema = yup.object().shape({
  id: yup.string(),
  name: yup
    .string()
    .required('Name must be filled')
    .min(2, 'Name must be min. 2 characters')
    .max(25, 'Name must be max. 25 characters'),

  date: yup
    .date()
    .required('Date must be set.')
    .min(actuallyYear - 110, 'Person must be younger then 110 years')
    .max(new Date(), 'Cannot set a future date!')
    .typeError('Date must be set!'),

  latitude: yup
    .number()
    .test(
      'exact-match',
      'A point in the map must be set.',
      (value: number | undefined) => value !== 0
    )
    .required('A point in the map must be set.'),
  longitude: yup
    .number()
    .test(
      'exact-match',
      'A point in the map must be set.',
      (value: number | undefined) => value !== 0
    ),

  altitude: yup.number().typeError('Altitude must be number.'),
  incident: yup
    .string()
    .required('Incident must be filled')
    .max(35, 'Incident must be max. 25 characters')
    .min(3, 'Incident must be min. 3 characters'),

  age: yup
    .number()
    .optional()
    .max(120, 'Age must be max. 120 years')
    .typeError('Age must be a number.')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (val === Number(val) ? val : null)),

  gender: yup
    .string()
    .max(25, 'Gender must be max. 25 characters')
    .required('Gender must be filled.')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (val === Number(val) ? val : null)),

  description: yup
    .string()
    .required('Description name must be filled')
    .min(10, 'Description must be min. 10 characters')
    .max(500, 'Description must be max. 500 characters'),
});

const _PoiForm: ForwardRefRenderFunction<PoiFormHandle, PoiFormProps> = (
  // eslint-disable-next-line no-empty-pattern
  {},
  ref
) => {
  // Refs
  const formModalRef = useRef<FormModalHandle>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  // Hooks
  const queryClient = useQueryClient();

  // State
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const {
    register,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<POI>({ resolver: yupResolver(schema) });

  // Services
  const { mutate: cratePoi, isLoading: isLoadingCreate } = useMutation({
    mutationKey: ['createPoi'],
    mutationFn: (payload: CreatePoi) => poiService.create(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['poi'] });
      toast.success('Incident has been added');
      setTimeout(() => {
        formModalRef.current?.hide();
      }, 1);
    },
  });

  const { mutate: updatePoi, isLoading: isLoadingUpdate } = useMutation({
    mutationKey: ['updatePoi'],
    mutationFn: (payload: UpdatePoi) => poiService.update(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['poi'] });
      toast.success('Incident has been updated');
      setTimeout(() => {
        formModalRef.current?.hide();
      }, 1);
    },
  });

  // Handlers
  const handleOnSubmit = async (data: POI) => {
    const response = await axios.get(
      `https://api.open-meteo.com/v1/elevation?latitude=${data.latitude}&longitude=${data.longitude}`
    );

    data.altitude = response.data.elevation[0];
    data.id ? updatePoi(data as UpdatePoi) : cratePoi(data as CreatePoi);
  };

  const show = (poi = emptyPoi, disabled = false) => {
    reset({ ...poi, date: formatToInputDate(poi.date) });

    setFormDisabled(disabled);
    formModalRef.current?.show();
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const hide = () => {};

  useImperativeHandle(ref, () => ({ show, hide }));

  const id = getValues('id');
  return (
    <FormModal
      ref={formModalRef}
      isLoading={isLoadingCreate || isLoadingUpdate}
      title={
        formDisabled
          ? 'Incident details'
          : id
          ? 'Update incident'
          : 'Create incident'
      }
      onSave={() => submitButtonRef.current?.click()}
      onReset={() => reset()}
      isDirty={!isDirty}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <BaseInput
          {...register('name')}
          label="Name"
          required
          disabled={formDisabled}
          error={!!errors?.name}
          errorText={errors.name?.message}
        />

        <BaseInput
          {...register('age')}
          label="Age"
          disabled={formDisabled}
          error={!!errors?.age}
          errorText={errors.age?.message}
        />

        <MultipleSelect
          multiple={false}
          label="Gender"
          control={control}
          list={
            ['male', 'female', 'unknown'].map((item) => ({
              name: item,
              value: item,
            })) || []
          }
          name="gender"
          isDisabled={formDisabled}
          error={!!errors?.gender}
        />

        <MultipleSelect
          multiple={false}
          label="Incident"
          required
          control={control}
          list={
            [
              'unknown',
              'flooding',
              'avalanche',
              'mudslide',
              'lost',
              'injury',
            ].map((item) => ({
              name: item,
              value: item,
            })) || []
          }
          name="incident"
          isDisabled={formDisabled}
          error={!!errors?.incident}
        />

        <BaseInput
          {...register('date')}
          label="Date"
          type="date"
          required
          disabled={formDisabled}
          error={!!errors?.date}
          errorText={errors.date?.message}
        />

        <div>
          <PoiMap
            setValues={setValue}
            getValues={getValues}
            isDisabled={formDisabled}
          />
        </div>

        <BaseInput
          {...register('latitude')}
          label="Latitude"
          disabled={true}
          required
          error={!!errors?.latitude}
          errorText={errors.latitude?.message}
        />

        <BaseInput
          {...register('longitude')}
          label="Longitude"
          disabled={true}
          required
          error={!!errors?.longitude}
          errorText={errors.longitude?.message}
        />

        {formDisabled ? (
          <BaseInput
            {...register('altitude')}
            label="Altitude"
            disabled={true}
            error={!!errors?.altitude}
            errorText={errors.altitude?.message}
          />
        ) : (
          <h3 className="my-2">Altitude will be set based on location point</h3>
        )}

        <BaseInput
          {...register('description')}
          label="Description"
          disabled={formDisabled}
          required
          error={!!errors?.description}
          errorText={errors.description?.message}
        />

        <button type="submit" ref={submitButtonRef} className="hidden" />
      </form>
    </FormModal>
  );
};

export const PoiForm = forwardRef(_PoiForm);
