/* eslint-disable no-console */
import {
  ForwardRefRenderFunction,
  Ref,
  RefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { BasicModal, ModalHandle } from 'common/components';
import {
  MapContainer,
  ZoomControl,
  TileLayer,
  FeatureGroup,
} from 'react-leaflet';
import locationLogsService from 'services/location-logs.service';
import L, { Map } from 'leaflet';
import { GeomanControls } from 'react-leaflet-geoman-v2';

export interface LocationLogsMapHandle {
  // eslint-disable-next-line no-unused-vars
  show: (id: string) => void;
}

const _LocationLogsMap: ForwardRefRenderFunction<LocationLogsMapHandle> = (
  _,
  ref
) => {
  // Refs
  const leafletMapRef: Ref<Map> = useRef(null);
  const locationLogMapRef = useRef<ModalHandle>(null);

  // State
  const [logId, setLogId] = useState<string>('');
  const [map, setMap] = useState<RefObject<L.Map>>();

  console.log('logId', logId);

  // Service
  const { data: locationLog, remove } = useQuery({
    queryKey: ['locationLog'],
    queryFn: () => locationLogsService.getOne(logId),
    enabled: !!logId,
  });

  // Effects
  useEffect(() => {
    if (map?.current && locationLog?.location?.coordinates) {
      const polyline = L.polyline(locationLog.location.coordinates);
      map.current.addLayer(polyline);
      const sum = locationLog.location.coordinates.reduce(
        (acc, curr) => {
          acc.lat += curr[0];
          acc.lng += curr[1];
          return acc;
        },
        { lat: 0, lng: 0 }
      );
      const average = {
        lat: sum.lat / +locationLog.location.coordinates.length,
        lng: sum.lng / +locationLog.location.coordinates.length,
      };
      map?.current.setView([average.lat, average.lng], 12);
    }
  }, [map?.current, locationLog]);

  // Handlers
  useImperativeHandle(ref, () => ({
    show: (id) => {
      setLogId(id);
      locationLogMapRef.current?.show();
    },
  }));
  return (
    <BasicModal ref={locationLogMapRef} onClose={remove} title="Location Log">
      <div className="w-[80vw] h-[400px]">
        <MapContainer
          ref={leafletMapRef}
          trackResize
          center={[64.3, 12.3]}
          zoom={4}
          style={{ height: '100%' }}
          maxZoom={13}
          className="!relative"
          zoomControl={false}
          whenReady={() => setMap(leafletMapRef)}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <FeatureGroup>
            <GeomanControls
              options={{
                position: 'bottomleft',
                drawText: false,
                drawCircleMarker: false,
                drawRectangle: false,
                cutPolygon: false,
                rotateMode: false,
                drawCircle: false,
              }}
              globalOptions={{
                continueDrawing: false,
                draggable: true,
                allowSelfIntersection: true,
              }}
            />
          </FeatureGroup>
        </MapContainer>
      </div>
    </BasicModal>
  );
};

export const LocationLogsMap = forwardRef(_LocationLogsMap);
