/* eslint-disable no-undef */
const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  env: process.env.NODE_ENV,
};

export const configuration = () => {
  if (!config.apiUrl) throw new Error('REACT_APP_API_URL is missing!');
  if (!config.env) throw new Error('ENV is missing!');
  return config;
};
