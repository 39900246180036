/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy, Suspense } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';

// Components
import { LoadingOverlay } from 'common/components/Loading/LoadingOverlay';
import { AuthorizationWrapper } from 'containers/AuthorizationWrapper';
import { MainLayout } from 'layouts/MainLayout';
import { Button } from 'common/components/Button/Button';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

// Types
import { UserRoles } from 'types/user.type';

// Routes
import { routes } from 'config/routesConfig';

// Layouts
import { AuthLayout } from 'layouts/AuthLayout';

// Context
import { AuthContext, useAuthHook } from 'context';
import { useMapHook } from 'context/useMapHook';
import { MapContext } from 'context/MapContext';

// Utils
import { configureAPI } from 'utils/api';
import { configuration } from 'config/config';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Leaflet styles
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Import pages
const BaseMap = lazy(() => import('containers/BaseMap/BaseMap'));
const Login = lazy(() => import('pages/Login/Login'));
const Register = lazy(() => import('pages/Register/Register'));
const UserMission = lazy(() => import('pages/User/UserMission'));
const UserTeam = lazy(() => import('pages/User/UserTeam'));
const Users = lazy(() => import('pages/Users/Users'));
const Teams = lazy(() => import('pages/Teams/Teams'));
const Missions = lazy(() => import('pages/Missions/Mission'));
const Poi = lazy(() => import('pages/Poi/Poi'));
const Organisations = lazy(() => import('pages/Organisation/Organisation'));

const MissionDetails = lazy(
  () => import('pages/MissionDetails/MissionDetails')
);

const App = () => {
  // Hooks
  const navigate = useNavigate();
  const authData = useAuthHook();
  const mapData = useMapHook();

  // Variables
  const { auth, isLoggedIn, handleLogout, loadingAuth } = authData;

  if (loadingAuth) {
    return <LoadingOverlay visible />;
  }

  // Handlers
  configureAPI({
    token: auth?.accessToken,
    onSignOut: handleLogout,
    onNotFound: () => {
      navigate('/404page');
    },
    onNoAccess: () => {
      navigate('/403page');
    },
  });

  const isAdmin = auth?.user?.roles.find(
    (item) => item.role.name === UserRoles.ADMIN
  );
  const isManager = auth?.user?.roles.find(
    (item) => item.role.name === UserRoles.MANAGER
  );

  const renderDevTools =
    configuration().env === 'production' ? null : (
      <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
    );

  return (
    <AuthContext.Provider value={authData}>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {renderDevTools}
      {!isLoggedIn && (
        <AuthLayout>
          <Suspense fallback={<LoadingOverlay visible />}>
            <Routes>
              <Route path={routes.auth.signIn.path} element={<Login />} />
              <Route path={routes.auth.register.path} element={<Register />} />

              <Route
                path="*"
                element={<Navigate to="/login" replace={true} />}
              />
            </Routes>
          </Suspense>
        </AuthLayout>
      )}

      {isLoggedIn && (
        <MapContext.Provider value={mapData}>
          <MainLayout>
            <Suspense fallback={<LoadingOverlay visible={true} />}>
              <Routes>
                <Route
                  path={routes.dashboard.map.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.dashboard.map.roles}
                      page={<BaseMap />}
                    />
                  }
                />

                <Route
                  path={routes.dashboard.home.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.dashboard.home.roles}
                      page={
                        <Navigate
                          to={`${
                            isAdmin || isManager
                              ? routes.userPage.users.path
                              : routes.userPage.userTeams.path
                          }`}
                          replace={true}
                        />
                      }
                    />
                  }
                />

                <Route
                  path={routes.userPage.userTeams.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.userTeams.roles}
                      page={<UserTeam />}
                    />
                  }
                />

                <Route
                  path={routes.userPage.userMissions.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.userMissions.roles}
                      page={<UserMission />}
                    />
                  }
                />

                <Route
                  path={routes.userPage.missionsDetails.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.missionsDetails.roles}
                      page={<MissionDetails />}
                    />
                  }
                />

                <Route
                  path={routes.userPage.users.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.users.roles}
                      page={<Users />}
                    />
                  }
                />

                <Route
                  path={routes.userPage.teams.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.teams.roles}
                      page={<Teams />}
                    />
                  }
                />

                <Route
                  path={routes.userPage.organisations.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.organisations.roles}
                      page={<Organisations />}
                    />
                  }
                />

                <Route
                  path={routes.userPage.missions.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.missions.roles}
                      page={<Missions />}
                    />
                  }
                />

                <Route
                  path={routes.userPage.poi.path}
                  element={
                    <AuthorizationWrapper
                      pageRoles={routes.userPage.poi.roles}
                      page={<Poi />}
                    />
                  }
                />

                <Route path="/404page" element={<div>Not Found</div>} />
                <Route path="/403page" element={<div>Access Forbidden</div>} />
                <Route
                  path="*"
                  element={<Navigate to="/404page" replace={true} />}
                />
              </Routes>

              {/* If user is not part of any of the organizations disable all the actions*/}
              {!auth?.user?.organisations?.length && !isAdmin && (
                <Modal open={true} title="This user has no organisation">
                  <Box
                    style={{
                      backgroundColor: 'white',
                      position: 'absolute',
                      top: '30%',
                      left: '50%',
                      padding: '2rem 3rem',
                      transform: 'translate(-50%, -50%)',
                      border: 'none',
                      borderRadius: '2rem',
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <h2 className="font-medium text-lg">
                      User is not currently assigned to any of the
                      organizations.
                    </h2>

                    <h2 className="font-medium pb-3 text-lg">
                      To be able to use the app you must be assigned to an
                      organization!
                    </h2>

                    <Button
                      bgColor="BLACK"
                      variant="contained"
                      className="!bg-black !p-4 !rounded-3xl !capitalize !text-lg"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </Button>
                  </Box>
                </Modal>
              )}
            </Suspense>
          </MainLayout>
        </MapContext.Provider>
      )}
    </AuthContext.Provider>
  );
};

export default App;
