/* eslint-disable no-unused-vars */
import {
  useRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';

// Components
import { ModalHandle, FormModal } from 'common/components';
import { Divider } from '@mui/material';

// Utils
import { formatDate } from 'utils/date.utils';

// Types
import { User, UserDetails } from 'types/user.type';

export interface NotificationModalHandle {
  show: ({
    content,
    team,
    createdAt,
  }: {
    content: string;
    team: string;
    createdAt: Date;
  }) => void;
}

interface State {
  content: string;
  team: string;
  createdAt: Date;
}

interface Props {
  notificationStatus: (User | UserDetails)[] | undefined;
}

const _NotificationModal: ForwardRefRenderFunction<
  NotificationModalHandle,
  Props
> = ({ notificationStatus }, ref) => {
  // State
  const [{ content, team, createdAt }, setDetails] = useState<State>(
    {} as State
  );

  const modalRef = useRef<ModalHandle>(null);

  useImperativeHandle(ref, () => ({
    show: ({ content, team, createdAt }) => {
      setDetails({ content, team, createdAt });
      modalRef.current?.show();
    },
  }));

  return (
    <FormModal ref={modalRef} title="Notification">
      <div className="flex flex-col gap-3 pt-3 pb-8">
        <div className="pb-5">
          <Divider />
          <div className="text-lg py-4">{content}</div>
          <Divider />
        </div>
        <div className="text-lg">
          <span className="font-medium">Team:</span> {team}
        </div>

        <div className="text-lg">
          <span className="font-medium">Date:</span> {formatDate(createdAt)}
        </div>
        <div className="text-lg">
          <span className="font-medium">Seen by:</span>
          {notificationStatus?.length ? (
            notificationStatus.map(({ firstName, lastName }, i) => (
              <span key={`${firstName}_${lastName}`} className="pl-1">
                {firstName + ' ' + lastName}
                {i < notificationStatus.length - 1 ? ',' : '.'}
              </span>
            ))
          ) : (
            <span className="pl-2">Not seen by anyone!</span>
          )}
        </div>
      </div>
    </FormModal>
  );
};

export const NotificationModal = forwardRef(_NotificationModal);
