import { Dispatch, SetStateAction, createContext } from 'react';
import { LatLng } from 'types/map.type';

export interface MapContextProps {
  latLong: { latitude: number | null; longitude: number | null };
  polygonCoordinates: LatLng[];
  setLatLong: Dispatch<
    SetStateAction<{
      latitude: null | number;
      longitude: null | number;
    }>
  >;
  setPolygon: Dispatch<SetStateAction<LatLng[]>>;
}

export const MapContext = createContext<MapContextProps>({
  latLong: { latitude: null, longitude: null },
  polygonCoordinates: [] as LatLng[],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLatLong: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPolygon: () => {},
});
