/* eslint-disable no-console */
/* eslint-disable prettier/prettier */

import {
  Select,
  MenuItem,
  SelectProps,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';

export interface OptionList {
  name: string;
  value: string;
}

export interface Props extends SelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  list: OptionList[];
  label?: string;
  name: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  error?: boolean;
  required?: boolean;
  multiSelect?: boolean;
}

export const MultipleSelect = ({
  list,
  label,
  control,
  name,
  isLoading = false,
  isDisabled = false,
  required,
  error = false,
  multiSelect = true,
  ...props
}: Props) => {
  // Renderers
  if (isLoading) <CircularProgress className="z-[2700]" />;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorData = errors as any;
        return (
          <div className="b-2">
            {label && (
              <InputLabel
                required={required}
                className="!pb-2  [&>.MuiInputLabel-asterisk]:text-red-600"
                htmlFor="outlined-basic"
              >
                <span className="text-black"> {label}</span>
              </InputLabel>
            )}
            <Select
              id={multiSelect ? 'multiple-select' : 'select'}
              multiple
              value={value?.length ? value : []}
              onChange={(e) => onChange(e.target.value)}
              fullWidth
              disabled={isDisabled}
              error={error}
              {...props}
            >
              {list.map(({ name, value }, index) => (
                <MenuItem key={`${name}_${index}`} value={value}>
                  <span className="!capitalize">{name}</span>
                </MenuItem>
              ))}
            </Select>
            {error && errorData && (
              <p className="!ml-0 MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {errorData[name].message}
              </p>
            )}
          </div>
        );
      }}
    />
  );
};
