/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { useRef, Ref, useState, useEffect, RefObject } from 'react';
import L, { Map } from 'leaflet';
import { GeomanControls } from 'react-leaflet-geoman-v2';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Helmet } from 'react-helmet';

// Leafleat components
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  FeatureGroup,
} from 'react-leaflet';

// Types
import { LatLng, MapShape } from 'types/map.type';

// Types
interface PoiMapProps {
  setValues: UseFormSetValue<any>;
  getValues?: UseFormGetValues<any>;
  isPolygon?: boolean;
  isDisabled?: boolean;
}

const PoiMap = ({ setValues, getValues, isDisabled = false }: PoiMapProps) => {
  // Refs
  const leafletMapRef: Ref<Map> = useRef(null);

  // State
  const [map, setMap] = useState<RefObject<L.Map>>();

  // Handlers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e?: any) => {
    if (e) {
      if (e.shape === 'Marker') {
        setValues('latitude', (e.layer._latlng as LatLng).lat, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValues('longitude', (e.layer._latlng as LatLng).lng, {
          shouldDirty: true,
          shouldValidate: true,
        });
        if (leafletMapRef?.current) {
          leafletMapRef.current.pm.Toolbar.setButtonDisabled('Marker', true);
        }
      }
    }

    if (!e) {
      if (leafletMapRef?.current) {
        const targets = Object.values((leafletMapRef.current as any)._targets);
        targets.forEach((target: any) => {
          let optionLayer: Array<any> = [];

          if (target.options) {
            optionLayer = Object.values(target?.options);
          }

          if (target?._latlng && optionLayer.length === 0) {
            setValues('latitude', (target?._latlng as LatLng)?.lat, {
              shouldDirty: true,
            });
            setValues('longitude', (target?._latlng as LatLng)?.lng, {
              shouldDirty: true,
            });
          }
        });
      }
    }
  };

  const handleOnRemove = (e: MapShape) => {
    if (e) {
      if (e.shape === 'Marker' && leafletMapRef?.current) {
        leafletMapRef.current.pm.Toolbar.setButtonDisabled('Marker', false);
        setValues('latitude', 0);
        setValues('longitude', 0);
      }
    }

    if (!e) {
      if (leafletMapRef?.current)
        leafletMapRef.current.pm.Toolbar.setButtonDisabled('Marker', true);
    }
  };

  // Declare and set marker variables
  let lat: number;
  let lng: number;
  let marker: L.Marker<any>;

  if (getValues) {
    lat = getValues('latitude');
    lng = getValues('longitude');
    marker = L.marker({ lat, lng });
  }

  useEffect(() => {
    if (leafletMapRef?.current && lat && lng) {
      leafletMapRef.current.addLayer(marker);
      leafletMapRef.current.pm.Toolbar.setButtonDisabled('Marker', true);
    }
  }, [map]);

  return (
    <div className="w-full h-[400px]">
      <Helmet>
        <title>Map - Safe Search</title>
      </Helmet>
      <MapContainer
        ref={leafletMapRef}
        trackResize
        center={[64.3, 12.3]}
        zoom={4}
        style={{ height: '100%' }}
        maxZoom={13}
        className="!relative"
        zoomControl={false}
        whenReady={() => setMap(leafletMapRef)}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {!isDisabled && (
          <FeatureGroup>
            <GeomanControls
              options={{
                position: 'bottomleft',
                drawText: false,
                drawCircleMarker: false,
                drawPolyline: false,
                drawRectangle: false,
                cutPolygon: false,
                rotateMode: false,
                drawCircle: false,
                drawPolygon: false,
                removalMode: !getValues ? true : false,
              }}
              onLayerRemove={(e) => handleOnRemove(e)}
              onCreate={handleOnChange}
              onEdit={handleOnChange}
              onDrag={handleOnChange}
              onActionClick={() => {
                handleOnChange();
              }}
              globalOptions={{
                continueDrawing: false,
                draggable: true,
                allowSelfIntersection: true,
              }}
            />
          </FeatureGroup>
        )}
      </MapContainer>
    </div>
  );
};

export default PoiMap;
