// Types
import {
  UpdateMission,
  CreateMission,
  Mission,
  WeatherResponse,
} from 'types/mission.type';

// Services
import BaseService from './base.service';

export class MissionService extends BaseService {
  async create(payload: CreateMission): Promise<Mission> {
    const mission = await this.axios.post('mission', payload);
    return mission.data;
  }

  async getOne(id: string): Promise<Mission> {
    const mission = await this.axios.get(`/mission/${id}`);
    return mission.data;
  }

  async userMission(missionId: string): Promise<Mission> {
    const mission = await this.axios.get(`/member/mission/${missionId}`);
    return mission.data;
  }

  async getAll(): Promise<Mission[]> {
    const mission = await this.axios.get('/mission/all');
    return mission.data;
  }

  async update({ id, ...payload }: UpdateMission): Promise<Mission> {
    const mission = await this.axios.patch(`/mission/${id}`, payload);
    return mission.data;
  }

  async delete(id: string): Promise<Mission> {
    const mission = await this.axios.delete(`/mission/${id}`);
    return mission.data;
  }

  async weatherData({
    latitude,
    longitude,
  }: {
    latitude: number | undefined;
    longitude: number | undefined;
  }): Promise<WeatherResponse> {
    const mission = await this.axios.get('/weather', {
      params: {
        latitude,
        longitude,
      },
    });
    return mission.data;
  }
}

export default new MissionService();
