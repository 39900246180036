/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable no-shadow */
import { UserAuthResponseType } from 'types/user.type';
import { useEffect, useState } from 'react';
import { configuration } from 'config';
import { Socket, io } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthHook = () => {
  const [auth, setAuth] = useState<UserAuthResponseType>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [errorText, setError] = useState('');
  const [live, setLive] = useState(false);
  const [socket, setSocket] =
    useState<Socket<DefaultEventsMap, DefaultEventsMap>>();

  const handleAuth = () => {
    setLoadingAuth(true);
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    if (auth?.accessToken) {
      setAuth(auth);
      setIsLoggedIn(true);
      setError('');
    }
    setLoadingAuth(false);
  };

  const handleLogin = (auth: UserAuthResponseType) => {
    setAuth(auth);
    setIsLoggedIn(true);
    localStorage.setItem('auth', JSON.stringify(auth));
    const socketInstance: Socket<DefaultEventsMap, DefaultEventsMap> = io(
      `${configuration().apiUrl?.replace('/api', '')}`
    );

    setSocket(socketInstance);
  };

  const handleLogout = (error = '') => {
    setError(error);
    setAuth(undefined);
    setIsLoggedIn(false);
    localStorage.clear();
  };

  useEffect(() => {
    handleAuth();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const socketInstance: Socket<DefaultEventsMap, DefaultEventsMap> = io(
        `${configuration().apiUrl?.replace('/api', '')}`
      );

      socketInstance.on('connect', () => {
        setLive(true);
      });

      socketInstance.on('disconnect', () => {
        setLive(false);
      });

      setSocket(socketInstance);

      return () => {
        if (socketInstance) {
          // eslint-disable-next-line no-console
          console.log('disconecting socket');
          socketInstance.disconnect();
          setLive(false);
        }
      };
    }
  }, [isLoggedIn]);

  return {
    auth,
    isLoggedIn,
    handleLogin,
    handleLogout,
    loadingAuth,
    errorText,
    socket,
    live,
    setLive,
  };
};
