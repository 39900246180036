import BaseService from './base.service';
import {
  CreateLocationLog,
  LocationLogsResponse,
  SummaryLocationLogsResponse,
  UpdateLocationLog,
} from 'types/locationLogs.type';

class LocationLogsService extends BaseService {
  async create(payload: CreateLocationLog) {
    const log = await this.axios.post('/location-log', payload);
    return log.data;
  }

  async getAllSummary(): Promise<SummaryLocationLogsResponse[]> {
    const logs = await this.axios.get('/location-log/summary');
    return logs.data;
  }

  async getOne(id: string): Promise<LocationLogsResponse> {
    const log = await this.axios.get(`/location-log/${id}`);
    return log.data;
  }

  async update({ id, ...payload }: UpdateLocationLog) {
    const log = await this.axios.patch(`/location-log/${id}`, payload);
    return log.data;
  }
}
export default new LocationLogsService();
