import { Button as MuiButton, styled, ButtonProps } from '@mui/material';

// Enum
import { Colors } from 'common/enums/Colors';

export interface CustomButtonProps extends ButtonProps {
  bgColor:
    | 'GREEN_PRIMARY'
    | 'GREY_PRIMARY'
    | 'BLACK'
    | 'WHITE'
    | 'RED'
    | 'TRANSPARENT';
}

export const StyledButton = styled(MuiButton)(
  ({ bgColor = 'GREEN_PRIMARY' }: CustomButtonProps) => ({
    color: bgColor === 'TRANSPARENT' ? 'black' : 'white',
    textTransform: 'capitalize',
    backgroundColor: Colors[bgColor],

    '&:hover': {
      backgroundColor: Colors[bgColor],
    },

    '&:disabled': {
      backgroundColor: Colors.GREY_PRIMARY,
    },
  })
);

export const Button = ({ bgColor, ...restProps }: CustomButtonProps) => {
  return <StyledButton {...restProps} bgColor={bgColor} />;
};
