import { useContext, useRef, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { AuthContext } from 'context';

// Components
import { Menu, Button, Avatar, Badge, styled, MenuItem } from '@mui/material';
import ProfileDetails from './_ProfileDetails';
import { ProfileDetailsHandle } from './_ProfileDetails';

const ProfileMenu = () => {
  // Ref
  const changePasswordFormRef = useRef<ProfileDetailsHandle>(null);

  // State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Context
  const { handleLogout, live } = useContext(AuthContext);

  // Hooks
  const navigate = useNavigate();

  // Handlers
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Style
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="hover:!bg-transparent"
      >
        {live ? (
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            variant="dot"
          >
            <Avatar className="cursor-pointer">A</Avatar>
          </StyledBadge>
        ) : (
          <Avatar className="cursor-pointer">A</Avatar>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            changePasswordFormRef.current?.show();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogout();
            navigate('/login');
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <ProfileDetails ref={changePasswordFormRef} title="User Profile" />
    </div>
  );
};

export default ProfileMenu;
