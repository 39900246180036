/* eslint-disable no-unused-vars */
import { OrganisationResponse, UserOrganisation } from './organisation.type';
import { UserRole } from './role.type';
import { BaseTeam, Team } from './team.type';

export enum UserRoles {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER',
  TEAM_LEADER = 'TEAM_LEADER',
}

export interface BaseUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: UserRole[];
  createdAt: string;
  updatedAt: string;
}

export interface UserTeam {
  team: BaseTeam;
}
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  roles: UserRole[];
  teams: UserTeam[];
  organisations: (UserOrganisation & {
    organisation?: OrganisationResponse;
  })[];
}

export interface UserAuthResponseType {
  accessToken?: string;
  user?: User;
}

export interface CreateUserType {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  roles: string[];
  organisations?: string[];
}

export interface UpdateUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  roles?: string[];
  organisations?: string[];
}

export interface UserFormType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  roles: string[];
  organisations?: string[];
}

export interface LoginUser {
  email: string;
  password: string;
}

export interface createUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  roles: Array<string>;
}

export interface ChangePassword {
  id?: string;
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export interface TeamUser {
  userId: string;
  teamId: string;
  updatedAt: string;
  createdAt: string;
  team?: Team;
  user?: BaseUser;
}

export interface UserDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  roles: UserRole[];
  teams: TeamUser[];
}
