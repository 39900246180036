import { CircularProgress } from '@mui/material';

export const LoadingOverlay = ({
  visible,
  containerClassName,
  contentClassName,
}: {
  visible: boolean;
  containerClassName?: string;
  contentClassName?: string;
}) => (
  <>
    {visible && (
      <div
        className={`absolute z-10  top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-slate-200 opacity-[0.8] ${containerClassName}`}
      >
        <CircularProgress className={`z-[2700] ${contentClassName}`} />
      </div>
    )}

    {!visible && <></>}
  </>
);
