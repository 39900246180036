/* eslint-disable no-undef */
// Types
export interface LayerItemProps {
  handleLayer: () => void;
  icon: 'coverage' | 'poi';
}

const _LayerItem = ({ handleLayer, icon }: LayerItemProps) => {
  return (
    <div onClick={handleLayer} className="cursor-pointer">
      {icon && <img src={require(`assets/icons/layers/${icon}.svg`)} />}
    </div>
  );
};

export default _LayerItem;
