/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  useRef,
  forwardRef,
  useState,
} from 'react';
import { BasicModal, ModalHandle } from './Modal';
import { Button } from '../Button';

// Types
export interface Action {
  title: string;
  onClick: () => void;
}

export interface Details {
  title: string;
  description: string;
}

export interface ConfirmModalHandle {
  show: (details: Details, action: Action) => void;
  hide: () => void;
}

export interface Props {
  isLoading?: boolean;
  className?: string;
}

const _ConfirmModal: ForwardRefRenderFunction<ConfirmModalHandle, Props> = (
  { isLoading, className = '' },
  ref
) => {
  const modalRef = useRef<ModalHandle>(null);

  const [details, setDetails] = useState<Details>();
  const [action, setAction] = useState<Action>();

  const show = (details: Details, actions?: Action) => {
    setDetails(details);
    setAction(actions);
    modalRef.current?.show();
  };

  const hide = () => {
    modalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  return (
    <BasicModal
      title=""
      ref={modalRef}
      isLoading={isLoading}
      showHeader={false}
    >
      <div className={`${className}`}>
        <div className="mb-5 text-2xl font-semibold text-center">
          {details?.title}
        </div>
        <div className="text-lg font-normal text-center">
          {details?.description}
        </div>

        {action && (
          <div className="flex justify-center gap-5 mt-14">
            <>
              <Button bgColor="TRANSPARENT" variant="outlined" onClick={hide}>
                Cancel
              </Button>

              <Button
                bgColor="RED"
                variant="contained"
                onClick={action?.onClick}
              >
                {action.title}
              </Button>
            </>
          </div>
        )}
      </div>
    </BasicModal>
  );
};

export const ConfirmModal = forwardRef(_ConfirmModal);
