/* eslint-disable no-unused-vars */
export enum Colors {
  GREY_PRIMARY = '#D9E6EA',
  GREY_SECONDARY = '#F7F7F7',
  GREY_TERTIARY = '#EAEAEA',
  GREEN_PRIMARY = '#1B4C25',
  GREEN_SECONDARY = '#B6DABF',
  YELLOW_PRIMARY = '#FFE790A1',
  BLACK = '#000000',
  WHITE = '#FFFFFF',
  RED = '#DC3427',
  TRANSPARENT = '',
}
