/* eslint-disable no-console */
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

// Components
import ProfileMenu from './Profile/ProfileMenu';
import Notifications from './_Notifications';

// Icons
import mobileLogo from 'assets/images/mobile-logo.svg';
import logo from 'assets/images/logo.png';
import hamburger from 'assets/icons/common/hamburger.svg';

// Context
import { AuthContext } from 'context';

// Types
import { UserRoles } from 'types/user.type';
import { UserNotification } from 'types/notification.type';

// Services
import usersService from 'services/users.service';

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const Navigation = ({ show, setShow }: Props) => {
  // Hooks
  const { auth, socket } = useContext(AuthContext);
  const navigate = useNavigate();
  const isManager = auth?.user?.roles.find(
    ({ role }) => role.name === UserRoles.MANAGER
  );
  const isAdmin = auth?.user?.roles.find(
    (item) => item.role.name === UserRoles.ADMIN
  );

  // Services
  const {
    data: notificatios,
    refetch,
    isLoading,
  } = useQuery<UserNotification[]>({
    queryKey: ['userNotifications'],
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    queryFn: async () =>
      await usersService.allNotifications(auth?.user?.id || ''),
  });

  const navigatoTo = () => {
    if (auth?.user) {
      if (auth.user.roles.find(({ role }) => role.name === UserRoles.USER))
        return navigate('/user-teams');

      if (auth.user.roles.find(({ role }) => role.name === UserRoles.ADMIN))
        return navigate('/members');

      if (isManager) return navigate('/teams');
    }
  };

  const userNotificatios =
    notificatios &&
    notificatios?.map((item) => ({
      ...item,
      notification: {
        ...item.notification,
        teamId:
          auth?.user?.teams.find(
            ({ team: { id } }) => id == item.notification.teamId
          )?.team.name || '',
      },
    }));

  useEffect(() => {
    if (socket) {
      socket.on('getNotification', () => {
        if (!isManager) toast.success('You have a new notification');
        refetch();
      });
    }
  }, [socket]);

  return (
    <div className="fixed top-0 left-0 w-full bg-white z-[1200] border-b-2 border-slate-300">
      <nav className="flex items-center justify-between p-2 mx-auto">
        <div className="pl-4 cursor-pointer" onClick={() => setShow(!show)}>
          <img src={hamburger} />
        </div>
        {
          <div className="block md:hidden">
            <img
              className="max-w-[1400px]  min-w-0 max-h-[50px] cursor-pointer"
              src={mobileLogo}
              alt="logo"
              onClick={navigatoTo}
            />
          </div>
        }
        {
          <div className="hidden md:block">
            <img
              className="max-w-[1400px]  min-w-0 max-h-[50px] cursor-pointer"
              src={logo}
              alt="logo"
              onClick={navigatoTo}
            />
          </div>
        }

        <div className="flex items-center">
          {!isAdmin && (
            <Notifications
              loading={isLoading}
              notifications={userNotificatios || ([] as UserNotification[])}
              onRefetch={refetch}
            />
          )}
          <ProfileMenu />
        </div>
      </nav>
    </div>
  );
};
