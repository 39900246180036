/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */

import { UserAuthResponseType } from 'types/user.type';
import { Dispatch, SetStateAction, createContext } from 'react';

import { Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';

export interface AuthContextProps {
  auth?: UserAuthResponseType;
  isLoggedIn: boolean;
  handleLogin: (auth: UserAuthResponseType) => void;
  handleLogout: (error?: string) => void;
  loadingAuth: boolean;
  errorText: string;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  live: boolean;
  setLive: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextProps>({
  auth: {},
  isLoggedIn: false,
  handleLogin: () => {},
  handleLogout: () => {},
  loadingAuth: false,
  errorText: '',
  socket: undefined,
  live: false,
  setLive: () => null,
});
