/* eslint-disable no-console */
// Types
import {
  type UpdateUser,
  type CreateUserType,
  type BaseUser,
  type User,
  UserDetails,
  ChangePassword,
  TeamUser,
} from 'types/user.type';

// Services
import BaseService from './base.service';

export class UserService extends BaseService {
  async create(payload: CreateUserType): Promise<User> {
    const user = await this.axios.post('/user', payload);
    return user.data;
  }

  async getAll(): Promise<User[]> {
    const users = await this.axios.get('user/all');
    return users.data;
  }

  async allNotifications(id: string) {
    const userNotifications = await this.axios.get(
      `user/${id}/notification/all`
    );
    return userNotifications.data;
  }

  async notificationStatus({
    userId,
    notificationId,
  }: {
    userId: string;
    notificationId: string;
  }) {
    const user = await this.axios.get(
      `user/${userId}/notification/${notificationId}`
    );

    return user.data as (User | UserDetails)[];
  }

  async getOne(id: string) {
    const user = await this.axios.get(`/user/${id}`);

    return user.data as User | UserDetails;
  }

  async userTeams(id: string): Promise<TeamUser[]> {
    const teams = await this.axios.get(`/user/${id}/team/all`);

    return teams.data;
  }

  async confirmSeenNotification({
    userId,
    notificationId,
  }: {
    userId: string;
    notificationId: string;
  }) {
    const notification = await this.axios.patch(
      `user/${userId}/notification/${notificationId}`
    );
    return notification.data;
  }

  async update({ id, ...payload }: UpdateUser): Promise<User> {
    const user = await this.axios.patch(`/user/${id}`, payload);
    return user.data;
  }

  async changePassword(changePassword: ChangePassword): Promise<User> {
    const updatedUser = await this.axios.post(
      'user/change-password',
      changePassword
    );
    return updatedUser.data;
  }

  async delete(id: string): Promise<BaseUser> {
    const user = await this.axios.delete(`/user/${id}`);
    return user.data;
  }

  async detachNotification(
    userId: string,
    notificationId: string
  ): Promise<unknown> {
    const mission = await this.axios.delete(
      `/user/${userId}/notification/${notificationId}`
    );
    return mission.data;
  }
}

export default new UserService();
