import { forwardRef } from 'react';
import { TextField, InputLabel, TextFieldProps, styled } from '@mui/material';

export type Props = {
  label?: string;
  errorText?: string;
  name?: string;
  multiline?: boolean;
} & TextFieldProps;

export const StyledInputLabel = styled(InputLabel)(() => ({
  color: 'red',
}));

export const StyledTextField = styled(TextField)(() => ({
  '& p': {
    margin: 0,
  },
}));

export const BaseInput = forwardRef<HTMLInputElement, Props>(
  (
    { label, name, required, errorText = '', multiline = false, ...restProps },
    ref
  ) => {
    return (
      <div className="w-full">
        {label && (
          <StyledInputLabel
            required={required}
            className="!pb-2"
            htmlFor="outlined-basic"
          >
            <span className="text-black"> {label}</span>
          </StyledInputLabel>
        )}
        <StyledTextField
          multiline={multiline}
          ref={ref}
          id="outlined-error-helper-text"
          name={name}
          fullWidth
          className="!mb-2"
          helperText={errorText}
          {...restProps}
        />
      </div>
    );
  }
);
