// Services
import BaseService from './base.service';

// Types
import {
  CreateNotification,
  UpdateNotification,
} from 'types/notification.type';

class NotificationService extends BaseService {
  async create(payload: CreateNotification) {
    return await this.axios.post('/notification', payload);
  }

  async getAll() {
    const notifications = await this.axios.get('/notification/all');
    return notifications.data;
  }

  async getOne(id: string) {
    const notification = await this.axios.get(`/notification/${id}`);
    return notification.data;
  }

  async update({ id, ...payload }: UpdateNotification) {
    const mission = await this.axios.patch(`/notification/${id}`, payload);
    return mission.data;
  }
}

export default new NotificationService();
