import { useContext, useState } from 'react';
import { FC, ReactElement } from 'react';
import { Navigation } from 'containers/Navigation/Navigation';
import { mainNavigationConfig } from 'config/navigationConfig';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'context';
import BaseMap from 'containers/BaseMap/BaseMap';
import { ReactComponent as MemberIcon } from 'assets/icons/common/member.svg';

interface PropsType {
  children: ReactElement;
}

export const MainLayout: FC<PropsType> = ({ children }) => {
  // State
  const [show, setShow] = useState(true);
  const { auth } = useContext(AuthContext);

  return (
    <div className="h-full max-h-fit bg-slate-500">
      <BaseMap />
      <Navigation setShow={setShow} show={show} />

      {/* Side nav */}
      <div
        className={`fixed left-0 h-full md:min-w-[400px] md:max-w-max w-full bg-white top-[71px] z-[1200] p-6 transition-all  duration-500 ${
          !show && '-translate-x-full'
        }`}
      >
        <div className="flex justify-center gap-3 mt-2 md:gap-5">
          {mainNavigationConfig?.map(({ roles, title, to }, index) => {
            return auth?.user?.roles?.map(
              (item) =>
                roles.includes(item.role.name) && (
                  <NavLink
                    key={`${title}_${to}_${index}`}
                    to={to}
                    className={({ isActive }) =>
                      isActive
                        ? 'py-2 px-3 border-gray-500 rounded-2xl bg-backgroundGreenPrimary text-white [&>div>svg>g>path]:!stroke-white'
                        : 'py-2 px-3 border-gray-500 rounded-2xl text-black [&>div>svg>g>path]:!stroke-black'
                    }
                  >
                    <div className="flex items-center text-[15px] md:text-base">
                      <MemberIcon />
                      {title}
                    </div>
                  </NavLink>
                )
            );
          })}
        </div>
        <div className="mt-10">{children}</div>
      </div>
    </div>
  );
};
