// Services
import BaseService from './base.service';

// Types
import { type Team, type CreateTeam, type UpdateTeam } from 'types/team.type';
import { type MissionTeam } from 'types/mission.type';

class TeamService extends BaseService {
  async create(payload: CreateTeam): Promise<Team> {
    return await this.axios.post('/team', payload);
  }

  async attachMission(teamId: string, missionId: string): Promise<MissionTeam> {
    const mission = await this.axios.post(
      `/team/${teamId}/mission/${missionId}`
    );
    return mission.data;
  }

  async getAll(): Promise<Team[]> {
    const teams = await this.axios.get('/team/all');
    return teams.data;
  }

  async getOne(id: string): Promise<Team> {
    const team = await this.axios.get(`/team/${id}`);
    return team.data;
  }

  async update({ id, ...payload }: UpdateTeam): Promise<Team> {
    const mission = await this.axios.patch(`/team/${id}`, payload);
    return mission.data;
  }

  async delete(id: string): Promise<Team> {
    const team = await this.axios.delete(`/team/${id}`);
    return team.data;
  }

  async detachMission(teamId: string, missionId: string): Promise<MissionTeam> {
    const mission = await this.axios.delete(
      `/team/${teamId}/mission/${missionId}`
    );
    return mission.data;
  }
}

export default new TeamService();
