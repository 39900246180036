// Import types
import { ReactElement } from 'react';
import { UserRoles } from 'types/user.type';
import { ReactComponent as MemberIcon } from 'assets/icons/common/member.svg';

export interface MainNavigationItem {
  title: string;
  to: string;
  // eslint-disable-next-line no-unused-vars
  icon?: ({ stroke }: { stroke: string }) => ReactElement;
  roles: UserRoles[];
}

export const mainNavigationConfig: MainNavigationItem[] = [
  {
    title: 'Teams',
    to: '/user-teams',
    roles: [UserRoles.USER],
  },
  {
    title: 'Missions',
    to: '/user-missions',
    roles: [UserRoles.USER],
  },
  {
    title: 'Members',
    to: '/members',
    roles: [UserRoles.ADMIN, UserRoles.MANAGER],
    icon: ({ stroke }) => <MemberIcon stroke={stroke} />,
  },
  {
    title: 'Teams',
    to: '/teams',
    roles: [UserRoles.MANAGER],
  },
  {
    title: 'Missions',
    to: '/missions',
    roles: [UserRoles.MANAGER],
  },
  {
    title: 'Organisations',
    to: '/organisations',
    roles: [UserRoles.ADMIN],
  },

  {
    title: 'Poi',
    to: '/poi',
    roles: [UserRoles.ADMIN],
  },
];
