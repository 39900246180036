/* eslint-disable no-console */
import {
  ForwardRefRenderFunction,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

// Context
import { AuthContext } from 'context';

// Components
import { BaseInput, Button, FormModal } from 'common/components';
import { FormModalHandle } from 'common/components/Modal';
import { Alert, Divider, InputAdornment } from '@mui/material';
import { LoadingOverlay } from 'common/components/Loading/LoadingOverlay';

// Types
import { ChangePassword, UserAuthResponseType } from 'types/user.type';

// Services
import usersService from 'services/users.service';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { LocationLogs, LocationLogsHandle } from './_LocationLogs';

interface ProfileDetailsProps {
  title: string;
}

export interface ProfileDetailsHandle {
  show: () => void;
}

const ProfileDetails: ForwardRefRenderFunction<
  ProfileDetailsHandle,
  ProfileDetailsProps
> = ({ title }, ref) => {
  // Refs
  const formModalRef = useRef<FormModalHandle>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const locationLogsRef = useRef<LocationLogsHandle>(null);

  // State
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Context
  const { auth, handleLogout } = useContext(AuthContext);
  const { user } = auth as UserAuthResponseType;

  // Form
  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .min(6, 'Password must be min. 6 characters')
      .max(55, 'Password must be max. 55 characters'),
    password: yup
      .string()
      .required('New password is required')
      .min(6, 'New password must be min. 6 characters')
      .max(55, 'New password must be max. 55 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'New passwords must match'),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<ChangePassword>({ resolver: yupResolver(schema) });

  // Services
  const { mutate, isLoading, error } = useMutation({
    mutationKey: ['changePassword'],
    mutationFn: async (changePassword: ChangePassword) => {
      await usersService.changePassword(changePassword);
    },
    onSuccess: () => {
      toast.success('Password has been changed succesfully!');
      handleLogout();
    },
  });

  // Handlers
  const handleOnSubmit = (data: ChangePassword) => {
    mutate({ id: user?.id, ...data });
  };

  const show = () => {
    formModalRef.current?.show();
  };

  useImperativeHandle(ref, () => ({
    show,
  }));

  const _error = error as AxiosError;

  return (
    <FormModal
      title={title}
      ref={formModalRef}
      onSave={() => submitButtonRef.current?.click()}
      onReset={() => reset()}
      isDirty={!isDirty}
    >
      <div>
        <div className="[&>div]:flex [&>div]:text-lg [&>div]:gap-2 [&>div>h3]:font-medium [&>div>span]:font-normal mb-4">
          <div>
            <h3>Name:</h3>
            <span>{user?.firstName + ' ' + user?.lastName}</span>
          </div>
          <div>
            <h3>Email:</h3> <span>{user?.email}</span>
          </div>
          <div>
            <h3>Roles:</h3>
            <span>
              {user?.roles.map(({ role }) => (
                <span key={role.id}>{role.name}</span>
              ))}
            </span>
          </div>
        </div>

        <Divider className="py-7 text-slate-400">Location logs</Divider>

        <Button
          bgColor="GREEN_PRIMARY"
          className="!w-full"
          size="large"
          onClick={() => locationLogsRef.current?.show()}
        >
          View logs
        </Button>

        <Divider className="py-7 text-slate-400">Change password</Divider>

        <div className="relative">
          <LoadingOverlay visible={isLoading} />
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="flex flex-col gap-2"
          >
            {_error && (
              <Alert severity="error" title="Error">
                <>{_error.message}</>
              </Alert>
            )}

            <BaseInput
              type={showPassword ? 'text' : 'password'}
              {...register('oldPassword')}
              label="Old password"
              InputProps={{
                endAdornment: (
                  <div onClick={() => setShowPassword(!showPassword)}>
                    <InputAdornment className="cursor-pointer" position="end">
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </InputAdornment>
                  </div>
                ),
              }}
              error={!!errors.oldPassword}
              errorText={errors.oldPassword?.message}
              required
            />

            <BaseInput
              type="password"
              {...register('password')}
              label="New password"
              error={!!errors.password}
              errorText={errors.password?.message}
              required
            />

            <BaseInput
              type="password"
              {...register('confirmPassword')}
              label="Confirm new password"
              error={!!errors.confirmPassword}
              errorText={errors.confirmPassword?.message}
              required
            />

            <button type="submit" className="hidden" ref={submitButtonRef} />
          </form>
        </div>
        <LocationLogs ref={locationLogsRef} />
      </div>
    </FormModal>
  );
};

export default forwardRef(ProfileDetails);
