/* eslint-disable no-unused-vars */
export enum Incidents {
  UNKNOWN = 'unknown',
  FLOODING = 'flooding',
  AVALANCHE = 'avalanche',
  MUDSLIDE = 'mudslide',
  LOST = 'lost',
  INJURY = 'injury',
}

export enum Gender {
  UNKNOWN = 'unknown',
  MALE = 'male',
  FEMALE = 'female',
}

export interface POI {
  id: string;
  date: Date | string;
  latitude: number;
  longitude: number;
  altitude: number;
  name: string;
  incident: Incidents;
  age: number | null;
  gender: Gender;
  description: string | null;
}

export interface CreatePoi {
  date: Date;
  latitude: number;
  longitude: number;
  altitude: number;
  name: string;
  incident: Incidents;
  age: number | null;
  gender: Gender;
  description: string | null;
}

export interface UpdatePoi {
  id: string;
  date: Date;
  latitude: number;
  longitude: number;
  altitude: number;
  name: string;
  incident: Incidents;
  age: number | null;
  gender: Gender;
  description: string | null;
}
