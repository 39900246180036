import { useState } from 'react';
import { LatLng } from 'types/map.type';

export const useMapHook = () => {
  // State
  const [latLong, setLatLong] = useState<{
    latitude: null | number;
    longitude: null | number;
  }>({
    latitude: null,
    longitude: null,
  });
  const [polygonCoordinates, setPolygon] = useState<LatLng[]>([]);

  return {
    latLong,
    setLatLong,
    setPolygon,
    polygonCoordinates,
  };
};
