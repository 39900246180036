/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import {
  useRef,
  Ref,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useCallback,
} from 'react';
import L, { Map } from 'leaflet';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { point, polygon, booleanPointInPolygon } from '@turf/turf';

// Context
import { MapContext } from 'context/MapContext';

// Components
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ImageOverlay,
  LayerGroup,
  ZoomControl,
  FeatureGroup,
  Polygon,
} from 'react-leaflet';
import { PoiForm, PoiFormHandle } from 'containers/PoiForm';
import _LayerItem from './_LayerItem';
import { LoadingOverlay } from 'common/components/Loading/LoadingOverlay';

// Icons
import redDot from 'assets/icons/redDot.svg';
import greenDot from 'assets/icons/greenDot.svg';
import greyDot from 'assets/icons/greyDot.svg';
import markerGreenIcon from 'assets/icons/greenMarker.png';
import markerYellowDarkIcon from 'assets/icons/markerYellowDarkIcon.png';
import markerBlueIcon from 'assets/icons/markerBlueIcon.png';

// Services
import poiService from 'services/poi.service';
import locationLogsService from 'services/location-logs.service';

// Context
import { AuthContext } from 'context';

// Types
import { UserRoles } from 'types/user.type';
import { toast } from 'react-toastify';
import {
  CreateLocationLog,
  LocationLogsResponse,
  UpdateLocationLog,
} from 'types/locationLogs.type';
import teamsService from 'services/teams.service';
import { Team, TeamMission } from 'types/team.type';
import { CreateNotification } from 'types/notification.type';
import notificationsService from 'services/notifications.service';
import { AxiosError } from 'axios';
import { Mission, Zones } from 'types/mission.type';
import { LatLng } from 'types/map.type';
import missionsService from 'services/missions.service';

interface Position {
  lat: number;
  lng: number;
  equal?: boolean;
  date?: Date;
}

interface Member {
  id: string;
  type: string;
  name: string;
  teams: string[];
  position: Position;
}

const parseCoverage = (value: number): string => {
  switch (value) {
    case 0:
      return '2G';
    case 1:
      return '3G';
    case 2:
      return '4G';
    case 3:
      return '5G';
    default:
      return '';
  }
};

const BaseMap = () => {
  // Refs
  const leafletMapRef: Ref<Map> = useRef(null);
  const pointsRef = useRef<any | null>(null);
  const locationsRef = useRef<any | null>(null);
  const coverageRef: Ref<L.LayerGroup<any>> | undefined = useRef(null);
  const poiFormRef = useRef<PoiFormHandle>(null);

  // Context
  const { latLong, polygonCoordinates, setPolygon } = useContext(MapContext);
  const { auth } = useContext(AuthContext);

  let isAdmin = false;

  auth?.user?.roles?.forEach(({ role: { name } }) => {
    if (!isAdmin) {
      isAdmin = name === UserRoles.ADMIN;
    }
  });

  const isManager =
    auth &&
    auth.user?.roles.find((role) => role.role.name === UserRoles.MANAGER);

  const initLocationLogId = localStorage.getItem('locationLogId');

  // State
  const [layers, setLayers] = useState<number[]>([0]);
  const [pointsLayer, setPointsLayer] = useState<boolean>(false);
  const [liveLayer, setLiveLayer] = useState<boolean>(false);
  const [coverageLayer, setCoverageLayer] = useState<boolean>(true);
  const [coverageType, setCoverage] = useState('2G');
  const [countryCoverageLoaded] = useState<boolean>(true);
  const [islandCoverageLoaded] = useState<boolean>(true);
  const [position, setPosition] = useState<Position | null>(null);
  const [positions, setPositions] = useState<Position[]>([]);
  const [locationLogId, setLocationLogId] = useState<string | null>(
    initLocationLogId
  );
  const [lastLogDate, setLastLogDate] = useState<Date>();
  const [team, setTeam] = useState<string | null>(null);
  const [mission, setMission] = useState<string | null>(null);
  const [selectedMission, setSelectedMission] = useState<Mission>();
  const [zones, setZones] = useState<Zones[]>([]);

  const [teamDetails, setTeamDetails] = useState<{
    newTeam: boolean;
    members: Member[];
  } | null>(null);

  const [join, setJoin] = useState(false);
  const [teamLocation, setTeamLocation] = useState<Position>();
  const [userLocation, setUserLocation] = useState<Position | null>();
  const [breachDetails, setBreachDetails] = useState<{
    breach: boolean | null;
    zone: string;
    allowed: string;
  }>({
    breach: null,
    zone: '',
    allowed: '',
  });
  const [zonePointColor, setZonePointColor] = useState(redDot);

  // Hooks
  const { socket, setLive } = useContext(AuthContext);
  const queryClient = useQueryClient();

  // Marker icons
  const getIcon = (iconColor: string) =>
    new L.Icon({
      iconUrl: iconColor,
      iconSize: [30, 32],
      iconAnchor: [15, 16],
      popupAnchor: [0, -35],
    });

  // Services
  const { data } = useQuery({
    queryKey: ['poi'],
    queryFn: async () => await poiService.getAll(),
  });

  // Services
  const { data: userTeams } = useQuery({
    queryKey: ['teams'],
    queryFn: async () => await teamsService.getAll(),
    onSuccess: (data: Team[]) => {
      if (data[0]?.missions[0]?.mission?.polygon) {
        setPolygon(data[0].missions[0].mission.polygon);
      }
      if (data[0]?.missions[0]?.mission?.zones)
        setZones(data[0]?.missions[0]?.mission?.zones);

      if (data.length) {
        setTeam(data[0].id);
      }
      if (!data?.length && socket) {
        socket.emit('joinWithoutTeam', { team: 'default' });
      }
    },
  });

  const { refetch: refetchMission } = useQuery(
    ['mission'],
    () => missionsService.getOne(mission || ''),
    {
      onSuccess(data: Mission) {
        setSelectedMission(data);
      },
      enabled: !!mission,
    }
  );

  const { mutate: createLocationLog } = useMutation({
    mutationKey: ['addLocationLog'],
    mutationFn: async (payload: CreateLocationLog) =>
      locationLogsService.create(payload),
    onSuccess: (data) => {
      setLocationLogId((data as { id: string }).id);
      localStorage.removeItem('locationLogId');
      localStorage.setItem('locationLogId', (data as { id: string }).id);
      setLastLogDate(positions[positions.length - 1].date);
    },
  });

  const { mutate: updateLocationLog } = useMutation({
    mutationKey: ['updateLocationLog'],
    mutationFn: async (payload: UpdateLocationLog) =>
      locationLogsService.update(payload),
    onSuccess: (data) => {
      if (
        (data as LocationLogsResponse).location.coordinates.length > 100 &&
        position
      ) {
        createLocationLog({
          userId: auth?.user?.id || '',
          teamId: team || '',
          location: {
            type: 'Polyline',
            coordinates: [[position.lat, position.lng]],
          },
        });
      }
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 422 && position)
        createLocationLog({
          userId: auth?.user?.id || '',
          teamId: team || '',
          location: {
            type: 'Polyline',
            coordinates: [[position.lat, position.lng]],
          },
        });
    },
  });

  // Services
  const {
    mutate: createNotification,
    error: createNotificationError,
    isLoading: isCreatingNotification,
  } = useMutation({
    mutationKey: ['createNotification'],
    mutationFn: (createNotification: CreateNotification) =>
      notificationsService.create(createNotification),
    onSuccess: () => {
      if (socket) {
        socket.emit('notification', team);
      }
      toast.success('Notification send.');
    },
  });

  // Handlers
  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const value: number = +e.target.value;

    let _layers = [...layers];

    if (!isChecked) {
      const index = _layers.indexOf(value);
      _layers.splice(index, 1);
    }

    if (isChecked) {
      _layers.push(value);
      _layers = _layers.sort((a, b) => a - b);
    }

    let coverageType = '';
    if (_layers.length) {
      _layers.forEach((item) => {
        coverageType = `${coverageType}${parseCoverage(item)}`;
      });
    }
    setCoverage(coverageType);

    setLayers([..._layers]);
    // setCountryCoverageUrl(countryUrl);
    // setCountryCoverageLoaded(!countryUrl.length);

    // setIslandCoverageUrl(islandUrl);
    // setIslandCoverageLoaded(!islandUrl.length);
  };

  const handleCoverage = () => {
    setCoverageLayer(!coverageLayer);
    const layerState = !coverageLayer;

    if (!layerState) {
      leafletMapRef?.current &&
        coverageRef.current?.removeFrom(leafletMapRef.current);
      return;
    }

    leafletMapRef?.current && coverageRef.current?.addTo(leafletMapRef.current);
  };

  const handlePoi = () => {
    setPointsLayer(!pointsLayer);
    const layerState = !pointsLayer;

    if (!layerState) {
      pointsRef.current?.removeFrom(leafletMapRef.current);
      return;
    }

    pointsRef.current?.addTo(leafletMapRef.current);
  };

  const handleLiveLocation = () => {
    setLiveLayer(!liveLayer);
    const layerState = !liveLayer;

    if (!layerState) {
      locationsRef.current?.removeFrom(leafletMapRef.current);
      return;
    }

    locationsRef.current?.addTo(leafletMapRef.current);
  };

  const handleBeforeUnload = useCallback(() => {
    updateLocationLog({
      id: locationLogId || '',
      userId: auth?.user?.id || '',
      teamId: team || '',
      location: {
        type: 'Polyline',
        coordinates: positions.map(({ lat, lng }) => [lat, lng]),
      },
    });
    setLastLogDate(positions[positions.length - 1].date);

    return setPositions([]);
  }, [team, position]);

  const handleSelectTeam = (e: ChangeEvent<HTMLSelectElement>) => {
    setTeam(e.target.value);
    const team = userTeams?.find(({ id }) => id === e.target.value);
    if (team?.missions[0]?.mission?.id) {
      setMission(team?.missions[0].mission.id);
    }

    if (team?.missions[0]?.mission?.zones) {
      if (team.missions[0].mission.polygon)
        setPolygon(team.missions[0].mission.polygon);
      setZones(team?.missions[0]?.mission?.zones);
    }

    socket?.emit('membersLocation', { newTeam: true, team: e.target.value });
  };

  const handleSelectMission = (e: ChangeEvent<HTMLSelectElement>) => {
    setMission(e.target.value);
    const _team = userTeams?.find(({ id }) => id === team);
    const _mission = _team?.missions.find(
      ({ mission: { id } }) => id === e.target.value
    );
    if (_mission?.mission?.polygon) setPolygon(_mission.mission.polygon);
    if (_mission?.mission.zones) setZones(_mission?.mission.zones);
  };

  useEffect(() => {
    if (socket && team) {
      socket.on('getMembersLocation', (data) => {
        setTeamDetails((prevState) => {
          if (!prevState) setLive(true);
          return JSON.parse(data);
        });
      });

      socket.on('memberLeft', () => {
        socket.emit('membersLocation', { team });
      });

      socket.on('onRemoveMember', () => {
        queryClient.invalidateQueries(['teams']);
      });

      socket.on('onAddMember', () => {
        queryClient.invalidateQueries(['teams']);
      });

      socket.on('updatedMember', () => {
        socket.emit('membersLocation', { team });
      });

      socket.on('joinedMember', (data) => {
        const _data = JSON.parse(data);
        if (team === _data.team) {
          socket.emit('membersLocation', { team });
        }
      });
    }
  }, [socket, team]);

  useEffect(() => {
    if (socket?.connected && auth && team) {
      if (!teamDetails?.members?.length && position && join) {
        socket.emit('joinRooms', {
          id: auth?.user?.id,
          type: isManager ? 'Manager' : 'Member',
          teams: userTeams?.map(({ id }) => id),
          name: auth?.user?.firstName,
          position,
        });
        socket?.emit('membersLocation', { newTeam: true, team });

        setJoin(false);
      }

      if (teamDetails?.members?.length && position?.equal === false && !join) {
        socket.emit('update', {
          id: auth?.user?.id,
          type: isManager ? 'Manager' : 'Member',
          teams: auth.user?.teams?.map(({ team: { id } }) => id),
          name: auth?.user?.firstName,
          position,
        });
        socket?.emit('membersLocation', { team });
      }
    }
  }, [socket?.connected, auth, position, join, team]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const watchLocation = navigator.geolocation.watchPosition(
        (location) => {
          const { latitude, longitude } = location.coords;

          setPosition((prev) => {
            if (!prev) setJoin(true);

            if (
              prev?.lat === +latitude.toFixed(4) &&
              prev?.lng === +longitude.toFixed(4)
            ) {
              return {
                lat: +latitude.toFixed(4),
                lng: +longitude.toFixed(4),
                equal: true,
              };
            }

            return {
              lat: +latitude.toFixed(4),
              lng: +longitude.toFixed(4),
              equal: false,
            };
          });
        },
        (error) => {
          console.error('Error getting location:', error);
          toast.error(error.message || '');
        },
        {
          enableHighAccuracy: true,
        }
      );

      setTimeout(() => {
        navigator.geolocation.clearWatch(watchLocation);
      }, 3500);
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    handleCoverage();
    handlePoi();
  }, []);

  useEffect(() => {
    if (latLong.latitude && latLong.longitude) {
      leafletMapRef?.current?.flyTo?.(
        [latLong.latitude, latLong.longitude],
        10
      );
    } else {
      leafletMapRef?.current?.flyTo?.([64.3, 12.3], 5);
    }
  }, [latLong.latitude, latLong.longitude]);

  useEffect(() => {
    if (teamDetails?.newTeam === true && leafletMapRef.current && team) {
      const sum = teamDetails.members.reduce(
        (acc, curr) => {
          acc.lat += curr.position.lat;
          acc.lng += curr.position.lng;
          return acc;
        },
        { lat: 0, lng: 0 }
      );
      const average = {
        lat: sum.lat / +teamDetails.members.length,
        lng: sum.lng / +teamDetails.members.length,
      };

      setTeamLocation({ lat: average.lat, lng: average.lng });

      leafletMapRef.current.setView([average.lat, average.lng], 15);
    }
  }, [teamDetails, leafletMapRef.current, team]);

  useEffect(() => {
    if (positions.length && team) {
      if (positions.length === 1 && !locationLogId) {
        createLocationLog({
          userId: auth?.user?.id || '',
          teamId: team,
          location: {
            type: 'Polyline',
            coordinates: positions.map(({ lat, lng }) => [lat, lng]),
          },
        });

        setLastLogDate(positions[positions.length - 1].date);
        // call the endpoint and setState
        return setPositions([]);
      }

      if (
        lastLogDate &&
        positions[positions.length - 1].date?.getTime() !==
          lastLogDate.getTime()
      ) {
        const difference: number = Math.abs(
          new Date().getTime() - lastLogDate.getTime()
        );

        // Convert the difference to minutes
        const differenceInMinutes: number = difference / 60000;

        if (differenceInMinutes < 2) return;

        if (differenceInMinutes > 2) {
          updateLocationLog({
            id: locationLogId || '',
            userId: auth?.user?.id || '',
            teamId: team,
            location: {
              type: 'Polyline',
              coordinates: positions.map(({ lat, lng }) => [lat, lng]),
            },
          });
          setLastLogDate(positions[positions.length - 1].date);

          return setPositions([]);
        }
      }
    }
  }, [position, team]);

  useEffect(() => {
    if (position) {
      if (!positions.length) {
        setLastLogDate(new Date());
      }
      const { lat, lng } = position;
      if (position.equal) return;
      setPositions((prevState) => {
        const _prevState = prevState;
        _prevState.push({ lat, lng, date: new Date() });
        return _prevState;
      });
    }
  }, [position]);

  useEffect(() => {
    if (position) {
      const markerPoint = point([position.lat, position.lng]);

      let _isMarkerInsidePolygon = true;

      selectedMission?.zones?.forEach((zone) => {
        zone.UserMissionZone?.forEach(({ userId, zoneId }) => {
          const zonePolygon = [...zone.polygon];
          zonePolygon.push(zone.polygon[0]);
          const zonePolygonFeature = polygon([
            zonePolygon.map(({ lat, lng }) => [lat, lng]),
          ]);

          const isMarkerInsidePolygon = booleanPointInPolygon(
            markerPoint,
            zonePolygonFeature
          );

          if (!isMarkerInsidePolygon) return;

          setBreachDetails((prevState) => {
            if (prevState.zone !== zoneId) {
              userId === auth?.user?.id
                ? setZonePointColor('greenDot')
                : setZonePointColor('redDot');
              createNotification({
                content: `${
                  auth?.user?.firstName
                } went inside the perimeter of ${
                  userId === auth?.user?.id
                    ? 'allowed zone!'
                    : 'not allowed zone!'
                }`,
                createdBy: `${auth?.user?.id}`,
                teamId: team || '',
                userId: '',
                // userTeams
                //   ?.find(({ id }) => id === team)
                //   ?.users.find(({ user: { roles } }) =>
                //     roles.find(
                //       ({ role: { name } }) => name === UserRoles.MANAGER
                //     )
                //   )?.user.id || '',
              });

              return {
                breach: isMarkerInsidePolygon,
                zone: zoneId,
                allowed:
                  userId === auth?.user?.id
                    ? 'allowed zone!'
                    : 'not allowed zone!',
              };
            }
            return prevState;
          });
          _isMarkerInsidePolygon = false;
        });
      });

      console.log('_isMarkerInsidePolygon', _isMarkerInsidePolygon);

      if (_isMarkerInsidePolygon) {
        setBreachDetails((prevState) => {
          setZonePointColor('greyDot');
          if (prevState.zone !== 'No zone') {
            createNotification({
              content: `${auth?.user?.firstName} went outside of the all the zones`,
              createdBy: `${auth?.user?.id}`,
              teamId: team || '',
              userId: '',
              // userTeams
              //   ?.find(({ id }) => id === team)
              //   ?.users.find(({ user: { roles } }) =>
              //     roles.find(
              //       ({ role: { name } }) => name === UserRoles.MANAGER
              //     )
              //   )?.user.id || '',
            });
            return {
              breach: _isMarkerInsidePolygon,
              zone: 'No zone',
              allowed: 'Out of the zones',
            };
          }
          return prevState;
        });
      }
    }
  }, [position]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  // Calculations

  const getHighestPoint = (polygonCoords: LatLng[]) => {
    let highestPoint = polygonCoords[0];

    for (let i = 1; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat > highestPoint.lat) {
        highestPoint = polygonCoords[i];
      }
    }

    return highestPoint;
  };

  useEffect(() => {
    if (mission) refetchMission();
  }, [mission]);

  return (
    <div className="w-full h-[100vh]">
      <Helmet>
        <title>Map - Safe Search</title>
      </Helmet>
      <MapContainer
        ref={leafletMapRef}
        trackResize
        center={[64.3, 12.3]}
        zoom={4}
        style={{ height: '100%' }}
        maxZoom={20}
        className="!relative"
        zoomControl={false}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <LoadingOverlay
          visible={!countryCoverageLoaded || !islandCoverageLoaded}
          containerClassName="z-[10000]"
        />

        <LayersControl position="topright" collapsed={false}>
          <LayerGroup ref={pointsRef}>
            <MarkerClusterGroup chunkedLoading>
              {data &&
                data?.map((poi) => (
                  <Marker
                    key={poi.id}
                    position={[poi.latitude, poi.longitude]}
                    icon={getIcon(markerGreenIcon)}
                    eventHandlers={{
                      click: () => poiFormRef.current?.show(poi, !isAdmin),
                    }}
                  />
                ))}
            </MarkerClusterGroup>
          </LayerGroup>

          <LayerGroup ref={locationsRef}>
            <FeatureGroup>
              {teamDetails?.members?.length &&
                teamDetails.members?.map(
                  ({ id, position: { lat, lng }, name, type }) => (
                    <Marker
                      key={id}
                      position={[lat, lng]}
                      eventHandlers={{
                        click: (event) => {
                          if (userLocation && teamLocation) {
                            leafletMapRef.current?.flyTo(
                              [teamLocation.lat, teamLocation.lng],
                              15,
                              {
                                animate: true,
                                duration: 4,
                              }
                            );
                          }
                          leafletMapRef.current?.flyTo(
                            [event.latlng.lat, event.latlng.lng],
                            15.5,
                            {
                              animate: true,
                              duration: 4, // duration in seconds
                            }
                          );
                          setUserLocation({
                            lat: event.latlng.lat,
                            lng: event.latlng.lng,
                          });
                        },
                      }}
                      icon={
                        type === 'Manager'
                          ? getIcon(
                              breachDetails.allowed === 'allowed zone!'
                                ? greenDot
                                : breachDetails.allowed === 'not allowed zone!'
                                ? redDot
                                : greyDot
                            )
                          : getIcon(
                              breachDetails.allowed === 'allowed zone!'
                                ? greenDot
                                : breachDetails.allowed === 'not allowed zone!'
                                ? redDot
                                : greyDot
                            )
                      }
                    >
                      <Popup>{name}</Popup>
                    </Marker>
                  )
                )}
            </FeatureGroup>
          </LayerGroup>

          {polygonCoordinates.length > 0 && (
            <LayerGroup>
              <Polygon
                positions={polygonCoordinates}
                fillOpacity={0}
                color="green"
                weight={2}
              />
            </LayerGroup>
          )}

          {zones.length > 0 &&
            zones.map(({ name, polygon }, index) => (
              <div key={index}>
                <LayerGroup>
                  <Polygon positions={polygon} weight={2} />
                </LayerGroup>
                <Popup
                  className="bg-transparent"
                  position={getHighestPoint(polygon)}
                >
                  {name}
                </Popup>
              </div>
            ))}

          {/* getHighestPoint */}

          {latLong.latitude && latLong.longitude && (
            <div>
              <Marker
                position={[latLong.latitude, latLong.longitude]}
                icon={getIcon(markerYellowDarkIcon)}
                zIndexOffset={9}
              >
                <Popup>
                  <div>
                    <h2>Latitude: {latLong.latitude}</h2>
                    <h2>Longitude: {latLong.longitude}</h2>
                  </div>
                </Popup>
              </Marker>
            </div>
          )}

          <LayerGroup ref={coverageRef}>
            <ImageOverlay
              opacity={0.7}
              url={
                coverageType.length
                  ? require(`assets/images/coverage/country/${coverageType}.png`)
                  : ''
              }
              bounds={[
                [50, -9],
                [71.83, 41],
              ]}
            />
            <ImageOverlay
              url={
                coverageType.length
                  ? require(`assets/images/coverage/island/${coverageType}.png`)
                  : ''
              }
              bounds={[
                [69.5, -5.4],
                [82.5, 40],
              ]}
            />
          </LayerGroup>
        </LayersControl>

        <div className="absolute top-40 right-7 z-[1000] flex flex-col gap-6 px-3 py-4 bg-black rounded-2xl">
          <_LayerItem handleLayer={handleCoverage} icon="coverage" />
          <_LayerItem handleLayer={handlePoi} icon="poi" />
          {!isAdmin && (
            <_LayerItem handleLayer={handleLiveLocation} icon="poi" />
          )}
        </div>

        <div className="absolute top-40 right-24 z-[1000] flex flex-col gap-6 px-3 py-4 [&>span]:leading-6 [&>span]:text-base rounded-2xl">
          <span>Signal coverage</span>
          <span>POI</span>
          <span>
            {!isAdmin && auth && (
              <>
                <select
                  value={team || ''}
                  onChange={handleSelectTeam}
                  style={{
                    padding: 2,
                    border: '1px solid black',
                    borderRadius: 5,
                    cursor: 'pointer',
                  }}
                >
                  {userTeams && userTeams.length ? (
                    userTeams?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No teams</option>
                  )}
                </select>
                <select
                  value={mission || ''}
                  onChange={handleSelectMission}
                  style={{
                    padding: 2,
                    border: '1px solid black',
                    borderRadius: 5,
                    cursor: 'pointer',
                  }}
                >
                  {team &&
                  userTeams?.find(({ id }) => team === id)?.missions.length ? (
                    userTeams
                      ?.find(({ id }) => team === id)
                      ?.missions.map(({ mission: { id, name } }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))
                  ) : (
                    <option disabled>No missions</option>
                  )}
                </select>
              </>
            )}
          </span>
        </div>

        <div className="absolute z-[1000] gap-3 bottom-10 text-base left-1/2 -ml-28 flex text-white bg-black flex-row p-3 items-end rounded-2xl">
          <div className="flex items-center justify-center gap-1 ">
            <label htmlFor="2G">2G:</label>
            <input
              className="cursor-pointer"
              defaultChecked
              onChange={(e) => {
                onCheckBoxChange(e);
              }}
              value={0}
              type="checkbox"
              id="2G"
            />
          </div>
          <div className="flex items-center justify-center gap-1">
            <label htmlFor="4G">4G:</label>
            <input
              className="cursor-pointer"
              onChange={(e) => {
                onCheckBoxChange(e);
              }}
              value={1}
              type="checkbox"
              id="4G"
            />
          </div>
          <div className="flex items-center justify-center gap-1">
            <label htmlFor="4G+">4G+:</label>
            <input
              className="cursor-pointer"
              onChange={(e) => {
                onCheckBoxChange(e);
              }}
              value={2}
              type="checkbox"
              id="3G"
            />
          </div>
          <div className="flex items-center justify-center gap-1">
            <label htmlFor="5G">5G:</label>
            <input
              className="cursor-pointer"
              onChange={(e) => {
                onCheckBoxChange(e);
              }}
              value={3}
              type="checkbox"
              id="5G"
            />
          </div>
        </div>
        <PoiForm ref={poiFormRef} />
      </MapContainer>
    </div>
  );
};

export default BaseMap;
