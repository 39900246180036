import { useLocation, useNavigate } from 'react-router-dom';
// Components
import { Button } from 'common/components';

// Icons
import logo from 'assets/images/mobile-logo.svg';
import { useEffect, useState } from 'react';

export const AuthNavigation = () => {
  const [path, setPath] = useState('');
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  return (
    <div className="fixed top-0 left-0 w-full bg-transparent z-[1500] border-b-2 border-slate-300">
      <nav className="flex items-center justify-between p-4 mx-auto">
        <img
          className="max-w-[1400px]  min-w-0 max-h-[50px]"
          src={logo}
          alt="logo"
        />

        <div>
          <Button
            bgColor="WHITE"
            onClick={() => {
              navigate(path === '/login' ? '/register' : '/login');
            }}
          >
            <span className="text-black capitalize ">
              {path === '/login' ? 'Sign up' : 'Log in'}
            </span>
          </Button>
        </div>
      </nav>
    </div>
  );
};
