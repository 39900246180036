import { Gender, Incidents, POI } from 'types/poi.type';

export const emptyPoi: POI = {
  id: '',
  date: '',
  latitude: 0,
  longitude: 0,
  altitude: 0,
  name: '',
  incident: Incidents.LOST,
  age: null,
  gender: Gender.MALE,
  description: null,
};
