// Types
import { UserRoles } from 'types/user.type';

// Declare interfaces
export interface ConfigRoute {
  path: string;
  roles: UserRoles[];
}

export type ConfigRoutes = Record<string, Record<string, ConfigRoute>>;

// Config
export const routes: ConfigRoutes = {
  auth: {
    signIn: {
      path: '/login',
      roles: [],
    },
    register: {
      path: '/register',
      roles: [],
    },
  },
  userPage: {
    teamLeader: {
      path: 'team-leader',
      roles: [UserRoles.TEAM_LEADER],
    },
    admin: {
      path: 'admin',
      roles: [UserRoles.ADMIN],
    },
    manager: {
      path: 'manager',
      roles: [UserRoles.MANAGER],
    },
    userTeams: {
      path: 'user-teams',
      roles: [UserRoles.USER],
    },
    userMissions: {
      path: 'user-missions',
      roles: [UserRoles.USER],
    },
    missionsDetails: {
      path: 'user-missions/:missionId',
      roles: [UserRoles.USER],
    },
    users: {
      path: 'members',
      roles: [UserRoles.ADMIN, UserRoles.MANAGER],
    },
    poi: {
      path: 'poi',
      roles: [UserRoles.ADMIN],
    },
    teams: {
      path: 'teams',
      roles: [UserRoles.MANAGER],
    },
    organisations: {
      path: 'organisations',
      roles: [UserRoles.ADMIN],
    },
    missions: {
      path: 'missions',
      roles: [UserRoles.MANAGER],
    },

    createUser: {
      path: 'create-user',
      roles: [UserRoles.ADMIN, UserRoles.MANAGER],
    },
  },
  dashboard: {
    home: {
      path: '/',
      roles: [
        UserRoles.ADMIN,
        UserRoles.MANAGER,
        UserRoles.TEAM_LEADER,
        UserRoles.USER,
      ],
    },
    map: {
      path: '/map',
      roles: [
        UserRoles.ADMIN,
        UserRoles.USER,
        UserRoles.TEAM_LEADER,
        UserRoles.MANAGER,
      ],
    },
  },
};
