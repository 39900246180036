/* eslint-disable no-console */
import { useRef, useState, MouseEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

// Components
import { Menu, Button, Badge, Divider } from '@mui/material';
import ProfileDetails from './Profile/_ProfileDetails';
import { ProfileDetailsHandle } from './Profile/_ProfileDetails';
import MailIcon from '@mui/icons-material/Mail';
import DraftsIcon from '@mui/icons-material/Drafts';
import {
  ConfirmModal,
  ConfirmModalHandle,
} from 'common/components/Modal/ConfirmModal';
import {
  NotificationModal,
  NotificationModalHandle,
} from './_NotificationModal';

// Types
import { UserNotification } from 'types/notification.type';
import { User, UserDetails } from 'types/user.type';

// Services
import usersService from 'services/users.service';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const Notifications = ({
  notifications,
  onRefetch,
}: {
  notifications: UserNotification[];
  loading?: boolean;
  onRefetch: () => void;
}) => {
  // Ref
  const changePasswordFormRef = useRef<ProfileDetailsHandle>(null);
  const notificationModalRef = useRef<NotificationModalHandle>(null);
  const confirmModalRef = useRef<ConfirmModalHandle>(null);

  // State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationStatus, setNotificationStatus] = useState<
    (User | UserDetails)[]
  >([]);

  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Services
  // eslint-disable-next-line no-undef
  const getNotificationStatus = async ({
    userId,
    notificationId,
  }: {
    userId: string;
    notificationId: string;
  }) => {
    try {
      const data = await usersService.notificationStatus({
        userId,
        notificationId,
      });
      setNotificationStatus(data);
    } catch (error) {
      toast.error('Cannot get notification status');
    }
  };

  const { mutate: updateNotification } = useMutation({
    mutationKey: ['updateUserNotification'],
    mutationFn: async ({
      userId,
      notificationId,
    }: {
      userId: string;
      notificationId: string;
    }) => {
      await usersService.confirmSeenNotification({
        userId,
        notificationId,
      });
    },
    onSuccess: () => {
      onRefetch();
    },
  });

  const { mutate: deleteNotification } = useMutation({
    mutationKey: ['detachNotification'],
    mutationFn: async ({
      userId,
      notificationId,
    }: {
      userId: string;
      notificationId: string;
    }) => await usersService.detachNotification(userId, notificationId),
    onSuccess: () => {
      toast.success('Notification has been deleted');
      onRefetch();
      setTimeout(() => {
        confirmModalRef.current?.hide();
      }, 1);
    },
  });

  const readNotifications = notifications.filter((item) => item.seen === true);

  const unReadNotifications = notifications.filter(
    (item) => item.seen === false
  );

  return (
    <div>
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className="hover:!bg-transparent"
        >
          <Badge badgeContent={unReadNotifications.length} color="primary">
            <MailIcon color="action" fontSize="large" />
          </Badge>
        </Button>
        <Menu
          id="basic-menu"
          className="overflow-hidden"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <div>
            <span className="text-xl font-semibold">
              <Divider textAlign="center">Uread</Divider>
            </span>
            <div className="flex flex-col">
              <div>
                {unReadNotifications.map(
                  ({
                    userId,
                    notificationId,
                    seen,
                    notification: { teamId, content },
                    createdAt,
                  }) => (
                    <MenuItem
                      key={`${userId}_${notificationId}`}
                      onClick={() => {
                        getNotificationStatus({ userId, notificationId });
                        !seen && updateNotification({ userId, notificationId });
                        notificationModalRef.current?.show({
                          content,
                          team: teamId,
                          createdAt,
                        });
                      }}
                    >
                      <div>
                        <MailIcon fontSize="small" />
                        <span className="px-2">Team: {teamId}</span>
                      </div>
                    </MenuItem>
                  )
                )}
                {!unReadNotifications?.length && (
                  <span className="p-4 font-medium">
                    No unread notifications!
                  </span>
                )}
              </div>
            </div>

            <div className="pt-7">
              <span className="text-xl font-semibold">
                <Divider textAlign="center">Read</Divider>
              </span>
              <div>
                {readNotifications.map(
                  ({
                    userId,
                    notificationId,
                    notification: { teamId, content },
                    createdAt,
                  }) => (
                    <MenuItem
                      key={`${userId}_${notificationId}`}
                      onClick={() => {
                        getNotificationStatus({ userId, notificationId });
                        updateNotification({ userId, notificationId });
                        notificationModalRef.current?.show({
                          content,
                          team: teamId,
                          createdAt,
                        });
                      }}
                    >
                      <div className="flex justify-between">
                        <DraftsIcon fontSize="small" />
                        <span className="px-2 max-w-[120px] overflow-hidden">
                          Team: {teamId}
                        </span>
                        <FontAwesomeIcon
                          className="w-4 pl-2 h-4 cursor-pointer"
                          icon={faTrashAlt}
                          onClick={(event) => {
                            event.stopPropagation();

                            confirmModalRef.current?.show(
                              {
                                title:
                                  'Are you sure you want to delete this notification?',
                                description:
                                  'This will delete incident permanently. You cannot undo this action.',
                              },
                              {
                                onClick: () => {
                                  deleteNotification({
                                    notificationId,
                                    userId,
                                  });
                                },
                                title: 'Remove',
                              }
                            );
                          }}
                        />
                      </div>
                    </MenuItem>
                  )
                )}
                {!readNotifications?.length && (
                  <span className="p-4 font-medium">
                    No read notifications!
                  </span>
                )}
              </div>
            </div>
          </div>

          <NotificationModal
            ref={notificationModalRef}
            notificationStatus={notificationStatus}
          />
        </Menu>
        <ProfileDetails ref={changePasswordFormRef} title="User Profile" />
        <ConfirmModal ref={confirmModalRef} className="p-5" />
      </div>
    </div>
  );
};

export default Notifications;
