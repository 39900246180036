/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as L from 'leaflet';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// L is needed to make leaflet available globally
window.L = L;

// Set custom icon
L.Icon.Default.mergeOptions({
  iconUrl: require('assets/icons/greenMarker.png'),
  iconSize: [30, 32],
  iconAnchor: [15, 30],
  popupAnchor: [0, -35],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
