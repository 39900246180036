import { CreatePoi, UpdatePoi, POI } from 'types/poi.type';
import BaseService from './base.service';

export class PoiService extends BaseService {
  async getAll(): Promise<POI[]> {
    const allPoi = await this.axios.get('/poi/all');
    return allPoi.data;
  }

  async create(payload: CreatePoi): Promise<POI> {
    const poi = await this.axios.post('/poi', payload);
    return poi.data;
  }

  async update({ id, ...payload }: UpdatePoi): Promise<POI> {
    const poi = await this.axios.patch(`/poi/${id}`, payload);
    return poi.data;
  }

  async delete(id: string): Promise<POI> {
    const poi = await this.axios.delete(`/poi/${id}`);
    return poi.data;
  }
}

export default new PoiService();
