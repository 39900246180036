import {
  ForwardRefRenderFunction,
  ReactElement,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import Modal from '@mui/material/Modal';
import { CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

export type ModalHandle = {
  show: () => void;
  hide: () => void;
};

export interface ModalProps {
  className?: string;
  contentClassName?: string;
  children: ReactElement | ReactNode;
  title: string;
  isLoading?: boolean;
  onClose?: () => void;
  showHeader?: boolean;
}

const _BasicModal: ForwardRefRenderFunction<ModalHandle, ModalProps> = (
  {
    className = '',
    contentClassName = '',
    children,
    title,
    isLoading = false,
    onClose,
    showHeader = true,
  },
  ref
) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    if (isLoading) return null;
    setShow(true);
  };

  const handleHide = () => {
    if (isLoading) return null;
    setShow(false);
    onClose?.();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  // Renderers
  if (!show) return null;
  return (
    <Modal
      title={title}
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleHide}
      className="relative"
    >
      <div
        className={`absolute max-w-2xl -translate-x-1/2 -translate-y-1/2 bg-white rounded-3xl top-1/2 left-1/2 min-w-fit overflow-hidden h-fit min-h-max max-h-[90vh] ${className}`}
      >
        {showHeader && (
          <div className="flex justify-between text-2xl bg-transparent pt-7 px-7 md:px-9">
            <div className="!bg-transparent">{title}</div>
            <button
              onClick={() => {
                setShow(false);
                handleHide();
              }}
              className="!bg-transparent"
            >
              {<FontAwesomeIcon className="text-lg" icon={faClose} />}
            </button>
          </div>
        )}
        <div className={`${contentClassName}`}>{children}</div>
        {isLoading && (
          <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-slate-200 opacity-[0.8]">
            <CircularProgress className="z-50" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export const BasicModal = forwardRef(_BasicModal);
