import { FC, ReactElement } from 'react';
import { AuthNavigation } from 'containers/AuthNavigation';

interface PropsType {
  children: ReactElement;
}

export const AuthLayout: FC<PropsType> = ({ children }) => {
  return (
    <div className="min-h-full pt-24 md:pt-56 md:px-16">
      <AuthNavigation />
      {children}
    </div>
  );
};
